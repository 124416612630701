import { LAYOUT_NAME_PG_GRID, LAYOUT_PG_GRID } from './layout-types';
import { PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS } from './pro-gallery';
export var RELATED_POSTS_WIDGET_DEFAULTS = {
    margins: 10,
    sidesPadding: 16,
    postSize: 254,
    contentHeight: 156,
    imageRatio: 2,
    spacing: 12,
    layoutName: LAYOUT_NAME_PG_GRID,
    layoutType: LAYOUT_PG_GRID,
    layoutCardsRowType: PG_LAYOUT_CARDS_ROW_TYPE_OPTIONS.FIT_TO_SCREEN,
    layoutPostsPerRow: 3,
};
export var RELATED_POSTS_SETTINGS_PARAMS = {
    postCount: {
        wixParam: 'postCount',
        appSettingsPath: 'style.numbers.postCount',
        defaultValue: 3,
        minValue: 1,
        maxValue: 50,
    },
};
export var RELATED_POSTS_LAYOUT_PARAMS = {
    backgroundColor: {
        wixParam: 'backgroundColor',
        appSettingsPath: 'style.colors.backgroundColor',
        defaultValue: 'color-5',
        defaultOpacity: 0,
    },
    sliderLoop: {
        wixParam: 'sliderLoop',
        appSettingsPath: 'style.booleans.sliderLoop',
        defaultValue: false,
    },
    sliderAutoSlide: {
        wixParam: 'sliderAutoSlide',
        appSettingsPath: 'style.booleans.sliderAutoSlide',
        defaultValue: false,
    },
    sliderPauseTime: {
        wixParam: 'sliderPauseTime',
        appSettingsPath: 'style.numbers.sliderPauseTime',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'sliderShowArrows',
        appSettingsPath: 'style.booleans.sliderShowArrows',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'sliderArrowsPosition',
        appSettingsPath: 'style.numbers.sliderArrowsPosition',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'sliderArrowsSize',
        appSettingsPath: 'style.numbers.sliderArrowsSize',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'sliderArrowsColor',
        appSettingsPath: 'style.colors.sliderArrowsColor',
        defaultValue: 'color-5',
    },
};
export var RELATED_POSTS_LAYOUT_PARAMS_MOBILE = {
    backgroundColor: {
        wixParam: 'backgroundColorMobile',
        appSettingsPath: 'style.colors.backgroundColorMobile',
        defaultValue: 'color-5',
        defaultOpacity: 0,
    },
    sliderLoop: {
        wixParam: 'sliderLoopMobile',
        appSettingsPath: 'style.booleans.sliderLoopMobile',
        defaultValue: false,
    },
    sliderAutoSlide: {
        defaultValue: true,
        wixParam: 'sliderAutoSlideMobile',
        appSettingsPath: 'style.booleans.sliderAutoSlideMobile',
    },
    sliderPauseTime: {
        wixParam: 'sliderPauseTimeMobile',
        appSettingsPath: 'style.numbers.sliderPauseTimeMobile',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'sliderShowArrowsMobile',
        appSettingsPath: 'style.booleans.sliderShowArrowsMobile',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'sliderArrowsPositionMobile',
        appSettingsPath: 'style.numbers.sliderArrowsPositionMobile',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'sliderArrowsSizeMobile',
        appSettingsPath: 'style.numbers.sliderArrowsSizeMobile',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'sliderArrowsColorMobile',
        appSettingsPath: 'style.colors.sliderArrowsColorMobile',
        defaultValue: 'color-5',
    },
};
